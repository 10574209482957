import React, {
  MutableRefObject,
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
  SyntheticEvent,
} from "react";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import CardBillingQueueUX from "./CardBillingQueueUX";
import CardPatientSearchBox from "react-lib/apps/HISV3/TPD/CardPatientSearchBox";

import { DateTextBox } from "react-lib/apps/common";
import { Button, Icon, Input } from "semantic-ui-react";
import ModReceiptCode from "./ModReceiptCode";
import { useInterval } from "react-lib/utils/hooksUtils";
import moment from "moment";
// Config
import CONFIG from "config/config";

const CARD_BILLING_QUEUE = "CardBillingQueue";

const POLLING_PATIENT_QUEUE = 1000 * 120;

const statusIPD = [
  "Admit",
  "Ward Admitted",
  "หมอมีคำสั่งให้ Discharge",
  "Cashier Request",
  "Cashier Discharged",
  "กลับบ้าน",
  "ส่งตัวไป OR",
];

const CardBillingQueue = (props: any) => {
  const [selectedRow, setSelectedRow] = useState<any>(null);

  const touchCountRef = useRef(0);
  const touchTimerRef = useRef<any>(null);
  const timestampSearchRef = useRef<any>(moment());

  useEffect(() => {
    props.onEvent({
      message: "BillingQueueAction",
      params: {
        action: "initial",
        card: CARD_BILLING_QUEUE,
      },
    });
  }, [props.selectedDevice]);

  useInterval(() => {
    const diff = moment().diff(timestampSearchRef.current, "milliseconds");
    const half = POLLING_PATIENT_QUEUE / 2;

    if (diff >= half) {
      props.onEvent({
        message: "BillingQueueAction",
        params: {
          action: "search",
          card: CARD_BILLING_QUEUE,
        },
      });
    }
  }, POLLING_PATIENT_QUEUE);

  const handleDoubleClick = useCallback((rowInfo: any) => {
    const { id } = rowInfo?.original;
    if (id) {
      props.onEvent({
        message: "BillingQueueAction",
        params: {
          action: "select_encounter",
          encounterId: id,
          card: CARD_BILLING_QUEUE,
        },
      });
      props?.forward();
    }
  }, []);

  const handleClickCheckExpenses = useCallback(
    (item: any) => (e: SyntheticEvent) => {
      e.stopPropagation();

      handleDoubleClick({ original: item });
    },
    [handleDoubleClick]
  );

  // UseMemo
  const encounterStatusOptions = useMemo(() => {
    return [
      { key: "ทั้งหมด", value: "ทั้งหมด", text: "ทั้งหมด" },
      ...(props.masterOptions?.EncounterStatus || []),
      ...(props.masterOptions?.EncounterStatusIPD || []).filter((item: any) =>
        statusIPD.includes(item.text)
      ),
    ];
  }, [props.masterOptions?.EncounterStatus, props.masterOptions?.EncounterStatusIPD]);

  const billingQueueList = useMemo(() => {
    return props.billingQueueList?.map((item) => ({
      ...item,
      action: (
        <div style={{ display: "grid", placeContent: "center" }}>
          <Button color="green" size="mini" onClick={handleClickCheckExpenses(item)}>
            ตรวจสอบค่าใช้จ่าย
          </Button>
        </div>
      ),
    }));
  }, [props.billingQueueList, handleClickCheckExpenses]);

  const handleChangeChecked = (name: string, checked: boolean) => {
    // console.log("handleChangeChecked name: ", name, event)
    props.onEvent({
      message: "BillingQueueAction",
      params: {
        action: "change_value",
        name,
        value: checked,
        card: CARD_BILLING_QUEUE,
      },
    });
  };

  const handleChangePatient = (id: any, hn: string, full_name: string) => {
    // console.log("handleChangePatient: ", id, hn, full_name)
    props.onEvent({
      message: "BillingQueueAction",
      params: {
        action: "change_value",
        name: "selectedPatient",
        value: { id, hn, full_name },
        card: CARD_BILLING_QUEUE,
      },
    });
  };

  const handleChangeDate = (name: string) => (date: string) => {
    props.onEvent({
      message: "BillingQueueAction",
      params: {
        action: "change_value",
        name,
        value: date,
        card: CARD_BILLING_QUEUE,
      },
    });
    // console.log("name: ", name, " date: ", date)
  };

  const handleChangeOption = (event: any, data: any) => {
    props.onEvent({
      message: "BillingQueueAction",
      params: {
        action: "change_value",
        name: data.name,
        value: data.value,
        card: CARD_BILLING_QUEUE,
      },
    });
  };

  const handleTouchStart = (rowInfo: any) => {
    touchCountRef.current++;

    if (touchCountRef.current === 1) {
      touchTimerRef.current = setTimeout(function () {
        touchCountRef.current = 0;
      }, 300);
    } else if (touchCountRef.current === 2) {
      clearTimeout(touchTimerRef.current);
      touchCountRef.current = 0;

      // handleDoubleClick(rowInfo);
    }
  };

  // console.log("CardBilling Queue: ", props)
  return (
    <>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_BILLING_QUEUE}`, null);
          props.setProp(`successMessage.${CARD_BILLING_QUEUE}`, null);
        }}
        error={props.errorMessage?.[CARD_BILLING_QUEUE]}
        success={props.successMessage?.[CARD_BILLING_QUEUE]}
      />
      <CardBillingQueueUX
        // loading
        loadingData={props.loadingData}
        // checekd
        billingFilterQueue={props.billingFilterQueue}
        // options
        masterOptions={props.masterOptions}
        encounterStatusOptions={encounterStatusOptions}
        // value
        billingQueueList={billingQueueList}
        // function
        onChangeChecked={handleChangeChecked}
        onChangeOption={handleChangeOption}
        onSearch={() => {
          props.onEvent({
            message: "BillingQueueAction",
            params: {
              action: "search",
              card: CARD_BILLING_QUEUE,
            },
          });
        }}
        selectedQueue={(state: any, rowInfo: any, column: any, instance: any) => {
          return {
            className:
              rowInfo?.original?.id && rowInfo?.original?.id === selectedRow?.id
                ? "blueSelectedRow"
                : "",
            onClick: () => {
              setSelectedRow(rowInfo?.original);
            },
            onTouchStart: () => handleTouchStart(rowInfo),
            // onDoubleClick: () => handleDoubleClick(rowInfo),
          };
        }}
        // component
        patientSearchBox={
          <CardPatientSearchBox
            controller={props.drugOrderQueueController}
            defaultValue={props.billingFilterQueue?.selectedPatient.hn}
            onEnterPatientSearch={handleChangePatient}
          />
        }
        receiptStartDate={
          <DateTextBox
            value={props.billingFilterQueue?.receiptStartDate}
            onChange={handleChangeDate("receiptStartDate")}
          />
        }
        receiptEndDate={
          <DateTextBox
            value={props.billingFilterQueue?.receiptEndDate}
            onChange={handleChangeDate("receiptEndDate")}
          />
        }
        pagination={
          <Pagination
            page={props.billingQueuePagination?.page}
            total={props.billingQueuePagination?.totalPage}
            onPrevious={() => {
              props.onEvent({
                message: "BillingQueuePagination",
                params: { action: "previous" },
              });
            }}
            onEnter={(page: string) => {
              if (Number.isInteger(parseInt(page))) {
                props.onEvent({
                  message: "BillingQueuePagination",
                  params: { action: "page_change", value: page },
                });
              }
            }}
            onNext={() => {
              props.onEvent({
                message: "BillingQueuePagination",
                params: { action: "next" },
              });
            }}
          />
        }
      />

      {!CONFIG.BIL_AUTO_RUN_RECEIPT && (
        <ModReceiptCode
          onEvent={props.onEvent}
          setProp={props.setProp}
          // data
          bilReceiptCodeDetail={props.bilReceiptCodeDetail}
          // CommonInterface
          successMessage={props.successMessage}
          errorMessage={props.errorMessage}
        />
      )}
    </>
  );
};

const paginationStyle = {
  flexCenter: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  fontPadding: {
    fontSize: "16px",
    padding: "0px 3px",
  },
  inputStyle: {
    width: "60px",
    fontSize: "16px",
  },
};

const Pagination = (props: any) => {
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    setPage(props.page);
  }, [props.page]);

  return (
    <div style={paginationStyle.flexCenter}>
      <Icon name="caret left" size="big" onClick={props.onPrevious} />
      <Input
        style={paginationStyle.inputStyle}
        value={page}
        onChange={(event: any) => {
          setPage(event.target.value);
        }}
        onKeyDown={(event: any, data: any) => {
          console.log("Event keydown: ", event.key, page);
          if (event.key === "Enter") {
            props.onEnter(page);
          }
        }}
      />
      <span style={paginationStyle.fontPadding}>/</span>
      <span style={paginationStyle.fontPadding}>{props.total || 1}</span>
      <Icon name="caret right" size="big" onClick={props.onNext} />
    </div>
  );
};
export default CardBillingQueue;
