import React, { useEffect, useState } from "react";
import { Label } from "semantic-ui-react";

import MainHIS from "./MainHIS";
import PatientLayout from "HIS/PatientLayout";

// Framework
import Tab from "react-lib/frameworks/Tab";
// Common
// import * as Common from "react-lib/apps/common";
import CardPrintList from "react-lib/apps/common/CardPrintList";
import ErrorBoundary from "react-lib/apps/common/ErrorBoundary";
import CardScanner from "react-lib/apps/HISV3/common/CardScanner";
import CardScannedDocument from "react-lib/apps/HISV3/common/CardScannedDocument";
import CardPatientPanel from "react-lib/apps/HISV3/common/CardPatientPanel";
import CardPHVList from "HIS/PHV/CardPHVList";
import * as MainHISI from "./MainHISInterface";

// REG
import CardConfirmCoverage from "react-lib/apps/HISV3/REG/CardConfirmCoverage";
import CardAddress from "react-lib/apps/HISV3/REG/CardAddress";
import CardRegSearch from "react-lib/apps/HISV3/REG/CardRegSearch";
import CardOpenEncounter from "react-lib/apps/HISV3/REG/CardOpenEncounter";
import CardPatientInfo from "react-lib/apps/HISV3/REG/CardPatientInfo";
import CardAppointmentEncounter from "react-lib/apps/HISV3/REG/CardAppointmentEncounter";
import CardActiveEncounter from "react-lib/apps/HISV3/REG/CardActiveEncounter";

// MSG
import ChatBox from "react-lib/apps/MSG/ChatBox";
// BIL
import CardBillingEncounters from "react-lib/apps/HISV3/BIL/CardBillingEncounters";
import CardMedicalFeeHistory from "react-lib/apps/HISV3/BIL/CardMedicalFeeHistory";
import CardBillingHistory from "react-lib/apps/HISV3/BIL/CardBillingHistory";
import CardFinancialAmountSet from "react-lib/apps/HISV3/BIL/CardFinancialAmountSet";
import CardPatientCoverage from "react-lib/apps/HISV3/BIL/CardPatientCoverage";
import CardMedicalFee from "react-lib/apps/HISV3/BIL/CardMedicalFee";
import CardBillingDeposit from "react-lib/apps/HISV3/BIL/CardBillingDeposit";
import CardBillingDepositHistory from "react-lib/apps/HISV3/BIL/CardBillingDepositHistory";
// HCU
import CardSearchPackage from "react-lib/apps/HISV3/HCU/CardSearchPackage";
import CardPurchaseList from "react-lib/apps/HISV3/HCU/CardPurchaseList";
import CardPurchaseHistory from "react-lib/apps/HISV3/HCU/CardPurchaseHistory";
import CardApplyPolicyPackage from "react-lib/apps/HISV3/HCU/CardApplyPolicyPackage";
import CardServiceRefund from "react-lib/apps/HISV3/ORM/CardServiceRefund";
// TPD
import CardAllergy from "react-lib/apps/HISV3/TPD/CardAllergy";
import CardDrugCounselling from "react-lib/apps/HISV3/TPD/CardDrugCounselling";
import CardDrugOrderHistory from "react-lib/apps/HISV3/TPD/CardDrugOrderHistory";
// PTM
import CardIntraHospitalTransfer from "react-lib/apps/HISV3/PTM/CardIntraHospitalTransfer";

// Config
import CONFIG from "config/config";

const LAYOUT_SETTINGS = [
  {
    patientList: { display: true, flex: 1, size: "max" },
    patientPanel: { display: false, flex: 0, size: "none" },
    patientRecord: { display: false, flex: 0, size: "none" },
  },
  {
    patientList: { display: false, flex: 0, size: "none" },
    patientPanel: { display: true, flex: 1, size: "min" },
    patientRecord: { display: true, flex: 4, size: "max" },
    backward: true,
  },
];

export const Main = (props: any) => {
  useEffect(() => {
    props.runSequence({ sequence: "SetPatient" });
    return () => {
      props.setState({
        SetPatientSequence: null,
        CreateUpdateEncounterSequence: null,
      });
    };
  }, []);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <PatientLayout
        LAYOUT_SETTINGS={LAYOUT_SETTINGS}
        onBackward={(layout: number) => {
          // issue 55929
          if (layout === 1) {
            props.setState({ CreateUpdateEncounterSequence: null }, () =>
              props.runSequence({
                sequence: "SetPatient",
                action: "backwithKeptSearchText",
              })
            );
          }
        }}
        patientList={(layoutProps: any) => (
          <div
            style={{ backgroundColor: "white", height: "100%", width: "100%" }}
          >
            {props.listView(layoutProps)}
          </div>
        )}
        patientPanel={(layoutProps: any) => (
          <div
            style={{
              backgroundColor: "white",
              height: "100%",
              width: "100%",
              borderRight: "solid #cccccc 1px",
            }}
          >
            {props.panelView(layoutProps)}
          </div>
        )}
        patientRecord={(layoutProps: any) => (
          <div style={{ height: "100%", width: "100%" }}>
            {props.recordView(layoutProps)}
          </div>
        )}
        initialLayout={props.initialLayout}
        resetLayout={props.resetLayout}
      />
    </div>
  );
};

Main.displayName = "ViewRegistration.Main"
export const listView = (context: MainHIS) => (layoutProps: any) => {
  return (
    <div style={{ height: "100%", padding: "10px" }}>
      <CardRegSearch
        setProp={context.controller.setProp}
        onEvent={context.controller.handleEvent}
        // seq
        runSequence={context.runSequence}
        SetPatientSequence={context.state.SetPatientSequence}
        // CommonInterface
        errorMessage={context.state.errorMessage}
        successMessage={context.state.successMessage}
        divisionType={context.state.divisionType}
        buttonLoadCheck={context.state.buttonLoadCheck}
        // callback
        forward={layoutProps?.forward || (() => {})}
        selectedDevice={context.state.selectedDevice}
        bilReceiptCodeDetail={context.state.bilReceiptCodeDetail}
      />
    </div>
  );
};

export const panelView = (context: MainHIS) => (layoutProps: any) =>
  (
    <div style={{ height: "100%", width: "100%", overflow: "scroll" }}>
      <CardPatientPanel
        //Feature #54627
        patientADR={context.state.patientADR}
        setProp={context.controller.setProp}
        selectedPatient={context.state.SetPatientSequence?.patient || {}}
        chatDetail={context.state.chatDetail}
        onEvent={context.controller.handleEvent}
        runSequence={context.runSequence}
        errorMessage={context.state.errorMessage}
        loadingStatus={context.state.loadingStatus}
        buttonLoadCheck={context.state.buttonLoadCheck}
        divisionType={context.state.divisionType}
        AllergySequence={context.state.AllergySequence}
        // Assessment
        AssessmentSequence={context.state.AssessmentSequence}
        // Suspending
        // encounterSuspension={context.state.encounterSuspension}
        // MedicalFeeHistorySequence={context.state.MedicalFeeHistorySequence}
        CreateUpdateEncounterSequence={
          context.state.CreateUpdateEncounterSequence
        }
        form={"ViewRegistration"}
        hideTabMenu={layoutProps.hideTabMenu}
        setHideTabMenu={layoutProps.setHideTabMenu}
        django={context.state.django}
      />
    </div>
  );

export const recordView = (context: MainHIS) => (layoutProps: any) =>
  {
    const divisionType = context.state.divisionType || "";
    // const selectedEncounter = context.state.selectedEncounter;
    // const isDrugDivision =  divisionType === "ห้องยา";
    const isGeneralDivision = ["ทั่วไป"].includes(divisionType);
    const isClinicWardDivision =
      [
        "หน่วยตรวจ",
        "หอผู้ป่วย",
        "พื้นที่ดูแล",
        "คลินิกพรีเมี่ยม",
        "หอผู้ป่วยพรีเมี่ยม",
      ].includes(divisionType);
    // const isDrugClinicWardDivision = isDrugDivision || isClinicWardDivision;
    // const isScheduleConfirmed = context.state.selectedOrOrder?.status && context.state.selectedOrOrder?.status > 4;

    // console.log('recordView isScheduleConfirmed: ', isScheduleConfirmed);
    const isPharmacist = !!context.state.django?.user?.role_types?.find(
      (role: string) => ["PHARMACIST"].includes(role)
    );

    return <ErrorBoundary>
      <Tab
        tabWidth="150px"
        contentWidth="0"
        selectedTabIndex={context.state.selectedRecordViewIndex}
        hideTabMenu={layoutProps.hideTabMenu}
        onTabChange={(index: number) => {
          context.controller.setProp("selectedRecordViewIndex", index);
        }}
        data={[
          { key: "Active Encounter",
            name: "Active Encounter",
            hide: false,
            render: () => (

              <CardActiveEncounter
                onEvent={context.controller.handleEvent}
                toMiscellaneous={(data: {patient_id: number, id: number, type: string}) => {
                  console.log('toMiscellaneous data: ', data);
                  context.controller.setProp("selectedTabIndex", 2)
                  context.controller.setProp("selectedTabPatientList", "queue")
                  context.controller.setProp("patientQueueEncounter", data)
                }}
                patientInfo={context.state.selectedPatient}
                encounterPatientList={context.state.encounterPatientList}
              />

            )
          },
          {
            key: "Patient History Viewer",
            name: "Patient History Viewer",
            hide:
              !["BACK_OFFICE"].includes(context.state.divisionType || ""),
            render: () => (
              <CardPHVList
                apiToken={context.controller.apiToken}
                division={context.controller.data.division}
                // function
                onEvent={context.controller.handleEvent}
                setProp={context.controller.setProp}
                // controller
                proxyController={context.proxyController}
                // CommonInterface
                errorMessage={context.state.errorMessage}
                successMessage={context.state.successMessage}
                buttonLoadCheck={context.state.buttonLoadCheck}
                // options
                masterOptions={context.state.masterOptions}
                masterData={context.controller.data.masterData}
                ChoiceTriage={context.state.ChoiceTriage}
                // seq
                runSequence={context.runSequence}
                AssessmentSequence={context.state.AssessmentSequence}
                HistoryCentralLabSequence={
                  context.state.HistoryCentralLabSequence
                }
                ORPostOperationSequence={
                  context.state.ORPostOperationSequence
                }
                ORHistorySequence={context.state.ORHistorySequence}
                ORRequestSequence={context.state.ORRequestSequence}
                PerioperativeNursingSequence={
                  context.state.PerioperativeNursingSequence
                }
                PreOperationSequence={context.state.PreOperationSequence}
                // SearchBox
                searchedItemListWithKey={
                  context.state.searchedItemListWithKey
                }
                // data
                selectedPatient={context.state.selectedPatient}
                selectedEncounter={context.state.selectedEncounter}
                loadingStatus={context.state.loadingStatus}
                userTokenize={context.state.userTokenize}
                selectedMainOrOrder={context.state.selectedMainOrOrder}
              />
            ),
          },
          {
            key: "รายการรอรับชำระและคืนเงิน",
            name: "รายการรอรับชำระและคืนเงิน",
            hide:
              !["การเงิน"].includes(context.state.divisionType || "") ||
              !context.state.selectedPatient,
            render: () => (
              <CardMedicalFee
                showAuthorize={CONFIG.FAX_CLAIM_AUTHORIZE}
                onEvent={context.controller.handleEvent}
                setProp={context.controller.setProp}
                patientInfo={context.state.selectedPatient}
                selectedEncounter={context.state.selectedEncounter}
                invoiceItemByModes={context.state.invoiceItemByModes}
                invoiceItemByOrders={context.state.invoiceItemByOrders}
                invoiceItemByItems={context.state.invoiceItemByItems}
                patientOrders={context.state.patientOrders}
                refundOrders={context.state.refundOrders}
                medicalFeeFilter={context.state.medicalFeeFilter}
                showFilter={!CONFIG.FEATURE_DISABLED_UP_TO_VERS["202401"]}
                BillPaymentSequence={context.state.BillPaymentSequence}
                errorMessage={context.state.errorMessage}
                buttonLoadCheck={context.state.buttonLoadCheck}
                masterOptions={context.state.masterOptions}
                // Suspending
                taskTrackingList={context.state.taskTrackingList}
                // BillPending
                billPendingDetail={context.state.billPendingDetail}
                // ReceiptCode
                bilReceiptCodeDetail={context.state.bilReceiptCodeDetail}
                // Bill Report Print
                billReportPrint={context.state.billReportPrint}
                // FaxClaimList
                faxClaimItemList={context.state.faxClaimItemList}
                // Package
                invoiceItemPackageList={context.state.invoiceItemPackageList}
                // Bill Patient Language
                billingPatientLanguage={context.state.billingPatientLanguage}
              />
            ),
          },
          {
            key: "ประวัติผู้ป่วย",
            name: "ประวัติผู้ป่วย",
            render: () => (
              <CardPatientInfo
                patient={context.state.SetPatientSequence?.patient || {}}
                setProp={context.controller.setProp}
                runSequence={context.runSequence}
                masterOptions={context.state.masterOptions}
                errorMessage={context.state.errorMessage}
                successMessage={context.state.successMessage}
                SetPatientSequence={context.state.SetPatientSequence}
                buttonLoadCheck={context.state.buttonLoadCheck}
              />
            ),
          },
          {
            key: "ที่อยู่ผู้ป่วย",
            name: "ที่อยู่ผู้ป่วย",
            render: () => (
              <CardAddress
                patient={context.state.SetPatientSequence?.patient || {}}
                setProp={context.controller.setProp}
                runSequence={context.runSequence}
                masterOptions={context.state.masterOptions}
                masterData={context.controller.data.masterData}
                errorMessage={context.state.errorMessage}
                successMessage={context.state.successMessage}
              />
            ),
          },
          {
            key: "รับรองสิทธิ",
            name: "รับรองสิทธิ",
            hide:
              !Number.isInteger(
                context.state.SetPatientSequence?.patient?.id
              ),
            render: () => (
              <ErrorBoundary>
                <CardConfirmCoverage
                  onEvent={context.controller.handleEvent}
                  setProp={context.controller.setProp}
                  runSequence={context.runSequence}
                  SetCoverageSequence={context.state.SetCoverageSequence}
                  masterOptions={context.state.masterOptions}
                  searchedItemList={context.state.searchedItemList}
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  errorCoverage={context.state.errorCoverage}
                  patient={context.state.SetPatientSequence?.patient}
                  onRefreshPatient={() => {
                    context.controller.handleEvent({
                      message: "HandleSelect",
                      params: {
                        action: "select",
                        patient: context.state.SetPatientSequence?.patient,
                        noClear: true,
                      },
                    });
                  }}
                />
              </ErrorBoundary>
            ),
          },
          {
            key: "เปิด Encounter ใหม่",
            name: "เปิด Encounter ใหม่",
            hide:
              !Number.isInteger(
                context.state.SetPatientSequence?.patient?.id
              ),
            render: () => (
              <CardOpenEncounter
                onEvent={context.controller.handleEvent}
                setProp={context.controller.setProp}
                runSequence={context.runSequence}
                patient={context.state.SetPatientSequence?.patient || {}}
                selectedPatient={context.state.selectedPatient}
                CreateUpdateEncounterSequence={
                  context.state.CreateUpdateEncounterSequence
                }
                appointmentEncounterList={
                  context.state.appointmentEncounterList
                }
                selectedAppointmentItems={
                  context.state.selectedAppointmentItems
                }
                masterOptions={context.state.masterOptions}
                errorMessage={context.state.errorMessage}
                successMessage={context.state.successMessage}
                buttonLoadCheck={context.state.buttonLoadCheck}
              />
            ),
          },
          {
            key: "เปิดจากนัดหมาย",
            name: "เปิดจากนัดหมาย",
            hide:
              !Number.isInteger(
                context.state.SetPatientSequence?.patient?.id
              ),
            render: () => <CardAppointmentEncounter />,
          },
          {
            key: "เปิดใบยาจัดส่งบ้าน",
            name: "เปิดใบยาจัดส่งบ้าน",
            render: () => <div>To be implemented</div>,
          },
          {
            key: "Allergy",
            name: "Allergy",
            hide:
              !(isGeneralDivision) && !(["ห้องยา"].includes(context.state.divisionType)),
            render: () => (
              <CardAllergy
                masterOptions={context.state.masterOptions}
                AllergySequence={context.state.AllergySequence}
                runSequence={context.runSequence}
                setProp={context.controller.setProp}
                onEvent={context.controller.handleEvent}
                errorMessage={context.state.errorMessage}
                successMessage={context.state.successMessage}
                searchedItemList={context.state.searchedItemList}
                selectedDivision={context.state.selectedDivision}
                DJANGO={context.state.django}
                restart={true}
                selectedEmr={context.state.selectedEmr}
              />
            ),
          },
          {
            key: "Counselling",
            name: "Counselling",
            hide: !(["ห้องยา"].includes(context.state.divisionType)),
            render: () => (
              <CardDrugCounselling
                // main event
                runSequence={context.runSequence}
                onEvent={context.controller.handleEvent}
                setProp={context.controller.setProp}
                // data
                buttonLoadCheck={context.state.buttonLoadCheck}
                successMessage={context.state.successMessage}
                errorMessage={context.state.errorMessage}
                userTokenize={context.state.userTokenize}
                DrugCounsellingSequence={
                  context.state.DrugCounsellingSequence
                }
                searchedItemListWithKey={
                  context.state.searchedItemListWithKey
                }
              />
            ),
          },
          {
            key: "Drug Order History",
            name: "Drug Order History",
            hide: !(["ห้องยา"].includes(context.state.divisionType)),
            render: () => (
              <div style={{ padding: "1rem 10px 1.5rem" }}>
                <CardDrugOrderHistory
                  patient_id={context.state.selectedPatient?.id}
                  patientName={context.state.selectedPatient?.full_name}
                  isDrugOrderHistory={true}
                  isPharmacist={isPharmacist}
                  frezzeRecordViewIndex={true}
                  DrugOrderHistorySequence={
                    context.state.DrugOrderHistorySequence
                  }
                  drugHistoryList={context.state.drugHistoryList}
                  drugHistoryOrderList={context.state.drugHistoryOrderList}
                  controller={context.drugOrderHistoryController}
                  runSequence={context.runSequence}
                  onEvent={context.controller.handleEvent}
                  setProp={context.controller.setProp}
                  selectedEncounter={context.state.selectedEncounter}
                  selectedEmr={context.state.selectedEmr}
                  selectedDivision={context.state.selectedDivision}
                  drugOrderQueue={context.state.drugOrderQueue}
                  drugOrder={context.state.selectedDrugOrderWorking}
                  drugOrderLog={context.state.drugOrderLog}
                  modNoteReject={context.state.modNoteReject}
                  layout={layoutProps.layout}
                  forward={layoutProps.forward}
                  DrugSelectSequence={context.state.DrugSelectSequence}
                  selectedPatient={context.state.selectedPatient}
                  selectedDrug={context.state.selectedDrug}
                  drugDose={context.state.drugDose}
                  drugDelivery={context.state.drugDelivery}
                  modConfirmAction={context.state.modConfirmAction}
                  modDrugRecommendation={context.state.modDrugRecommendation}
                  masterOptions={context.state.masterOptions}
                  masterData={context.controller.data.masterData}
                  DrugOrderActionSequence={
                    context.state.DrugOrderActionSequence
                  }
                  medErrorWorking={
                    context.state.MedErrorListSequence?.medErrorWorking
                  }
                  errorMessage={context.state.errorMessage}
                  loadingStatus={context.state.loadingStatus}
                  successMessage={context.state.successMessage}
                  // DrugTransferRequest
                  drugTransferRequestSequence={
                    context.state.DrugTransferRequestSequence
                  }
                  drugOrderHistoryController={
                    context.drugOrderHistoryController
                  }
                  drugRequestList={context.state.drugRequestList}
                  selectedDevice={context.state.selectedDevice}
                  drugTransferRequestlist={
                    context.state.drugTransferRequestlist
                  }
                  drugTransferLog={context.state.drugTransferLog}
                  drugTransferRequestDetail={
                    context.state.drugTransferRequestDetail
                  }
                  AllergySequence={context.state.AllergySequence}
                  //ReturnOrder
                  ReturnOrderSequence={context.state.ReturnOrderSequence}
                  OrderReturnList={context.state.OrderReturnList}
                  DrugReturnList={context.state.DrugReturnList}
                  OrderHistory={context.state.OrderHistory}
                  OrderReturnLog={context.state.OrderReturnLog}
                  // StockManagement
                  lotNoExpList={context.state.lotNoExpList}
                  searchedItemListWithKey={
                    context.state.searchedItemListWithKey
                  }
                  type={"PATIENT_HISTORY"} // for flag selectEncounter in function SelectDrugOrderWorking
                  // MedReconcile
                  divisionType={context.state.divisionType}
                  medReconcileIndex={context.state.medReconcileIndex}
                  django={context.state.django}
                />
              </div>
            ),
          },
          {
            key: "Document Scan",
            name: "Document Scan",
            // hide: false,
            render: () => (
              <div>
                {context.state.SetPatientSequence?.patient?.hn !== undefined ? (
                  <ErrorBoundary>
                    <CardScanner
                      onClose={() => {
                        context.setState({
                          openModScanner: !context.state.openModScanner,
                        });
                        context.setState({ openMorePopup: false });
                      }}
                      patientInfo={context.state.selectedPatient}
                      controller={context.scannedDocumentController}
                      patientId={context.state.selectedPatient?.id}
                      PATIENT_DATA={context.state.patientData}
                      onNoPatient={() =>
                        context.setState({ openModScannerError: true })
                      }
                      django={context.state.django}
                      setProp={context.controller.setProp}
                    />
                  </ErrorBoundary>
                ) : (
                  <div>
                    {context.state.SetPatientSequence?.patient &&
                      alert("กรุณาระบุ HN")}
                  </div>
                )}
              </div>
            ),
          },
          {
            key: "รายการสถานะผู้ป่วย",
            name: "รายการสถานะผู้ป่วย",
           hide:
                !Number.isInteger(context.state.SetPatientSequence?.patient?.id) ||
                !["การเงิน", "BACK_OFFICE"].includes(context.state.divisionType || ""),
            render: () => (
              <CardBillingEncounters
                setProp={context.controller.setProp}
                divisionList={context.state.divisionList}
                onEvent={context.controller.handleEvent}
                runSequence={context.runSequence}
                BillingHistorySequence={context.state.BillingHistorySequence}
                errorMessage={context.state.errorMessage}
                successMessage={context.state.successMessage}
              />
            ),
          },
          {
            key: "เอกสารทั้งหมด",
            name: "เอกสารทั้งหมด",
            hide:
                !Number.isInteger(context.state.SetPatientSequence?.patient?.id) ||
                !["การเงิน", "BACK_OFFICE"].includes(context.state.divisionType || ""),
            render: () => (
              <CardBillingHistory
                onEvent={context.controller.handleEvent}
                setProp={context.controller.setProp}
                // seq
                runSequence={context.runSequence}
                BillingHistorySequence={context.state.BillingHistorySequence}
                BillPaymentSequence={context.state.BillPaymentSequence}
                // data
                divisionList={context.state.divisionList}
                invoiceHistory={context.state.invoiceHistory}
                // options
                masterOptions={context.state.masterOptions}
                // CommonInterface
                errorMessage={context.state.errorMessage}
                successMessage={context.state.successMessage}
                selectedEncounter={context.state.selectedEncounter}
                patientInfo={context.state.selectedPatient}
                buttonLoadCheck={context.state.buttonLoadCheck}
                // Medical Fee
                invoiceItemByModes={context.state.invoiceItemByModes}
                invoiceItemByOrders={context.state.invoiceItemByOrders}
                invoiceItemByItems={context.state.invoiceItemByItems}
                refundOrders={context.state.refundOrders}
                patientOrders={context.state.patientOrders}
                // Bill Report Print
                billReportPrint={context.state.billReportPrint}
                // Bill Patient Language
                billingPatientLanguage={context.state.billingPatientLanguage}
              />
            ),
          },
          {
            key: "Medical_Bill_Summary",
            name: "สรุปค่ารักษาพยาบาล",
            hide:
                !Number.isInteger(context.state.SetPatientSequence?.patient?.id) ||
                !["การเงิน", "BACK_OFFICE"].includes(context.state.divisionType || ""),
            render: () => (
              <CardMedicalFeeHistory
                setProp={context.controller.setProp}
                onEvent={context.controller.handleEvent}
                runSequence={context.runSequence}
                masterOptions={context.state.masterOptions}
                errorMessage={context.state.errorMessage}
                successMessage={context.state.successMessage}
                selectedEncounter={context.state.selectedEncounter}
                buttonLoadCheck={context.state.buttonLoadCheck}
                MedicalFeeHistorySequence={
                  context.state.MedicalFeeHistorySequence
                }
                loadingStatus={context.state.loadingStatus}
                divisionType={context.state.divisionType}
              />
            ),
          },
          {
            key: "กำหนดวงเงินรายครั้ง",
            name: "กำหนดวงเงินรายครั้ง",
            hide:
                !Number.isInteger(context.state.SetPatientSequence?.patient?.id) ||
                !["การเงิน"].includes(divisionType),
            render: () => (
              <CardFinancialAmountSet
                // origin function
                setProp={context.controller.setProp}
                onEvent={context.controller.handleEvent}
                runSequence={context.runSequence}
                // origin data
                errorMessage={context.state.errorMessage}
                successMessage={context.state.successMessage}
                buttonLoadCheck={context.state.buttonLoadCheck}
                // data
                FinancialAmountSetSequence={
                  context.state.FinancialAmountSetSequence
                }
                financialAmountList={context.state.financialAmountList}
                modCoverageApprove={context.state.modCoverageApprove}
              />
            ),
          },
          {
            key: "วงเงินคงเหลือ",
            name: "วงเงินคงเหลือ",
            hide:
              !Number.isInteger(context.state.SetPatientSequence?.patient?.id) ||
              !isGeneralDivision,
            render: () => (
              <CardFinancialAmountSet
                // origin function
                setProp={context.controller.setProp}
                onEvent={context.controller.handleEvent}
                runSequence={context.runSequence}
                // origin data
                errorMessage={context.state.errorMessage}
                successMessage={context.state.successMessage}
                buttonLoadCheck={context.state.buttonLoadCheck}
                // data
                FinancialAmountSetSequence={
                  context.state.FinancialAmountSetSequence
                }
                financialAmountList={context.state.financialAmountList}
                modCoverageApprove={context.state.modCoverageApprove}
                // config
                readOnly={true}
              />
            ),
          },
          {
            key: "สิทธิการรักษา",
            name: "สิทธิการรักษา",
            hide:
              !Number.isInteger(context.state.SetPatientSequence?.patient?.id) ||
              !["การเงิน"].includes(divisionType),
            render: () => (
              <CardPatientCoverage
                onEvent={context.controller.handleEvent}
                // data
                patientCoverageList={context.state.patientCoverageList}
              />
            ),
          },
          {
            // Todo: get channel id for given division, hn with PRX/resolve-chat-channel/
            key: "Telehealth_Register",
            name: (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <label>Telehealth</label>
                {!!context.state.chatDetail?.unread_message_count && (
                  <Label
                    data-testid="msg-noti"
                    circular
                    color={"red"}
                    size="mini"
                  >
                    {context.state.chatDetail.unread_message_count}
                  </Label>
                )}
              </div>
            ),
            hide:
              // !context.state.selectedEncounter ||
              !(isClinicWardDivision || isGeneralDivision) ||
              context.state.chatDetail === null ||
              context.state.selectedPatient.profile_status !== "VERIFIED",
            render: () => (
              <div>
                <ChatBox
                  ref={(ref: any) => (context.chatBoxRef = ref)}
                  // controller
                  controller={context.chatController}
                  classifyController={context.classifyController}
                  diagFormController={context.diagFormController}
                  // data
                  messageViewStyle={{ height: "81vh" }}
                  userId={context.controller.data.user?.toString() || ""}
                  apiToken={context.controller.apiToken}
                  division_id={context.state.selectedDivision?.id}
                  isPatient={false}
                  nullId={false}
                  readOnly={false}
                  fullname={"fullname"}
                  username={"username"}
                  encounterId={context.state.selectedEncounter?.id}
                  patientData={{}}
                  patientId={context.state.selectedPatient?.id}
                  match={{
                    params: {
                      chatChannelId: context.state.chatDetail?.chat_channel,
                    },
                  }}
                  // callback
                  onDidMount={() =>
                    context.controller.setProp(
                      "chatDetail.unread_message_count",
                      0
                    )
                  }
                  onNavigationMessage={({ content }: any) => content}
                  onCallGetLastMessage={() => {}}
                  onCloseVideoCall={() => {}}
                  onOpenVideoCall={(url: string) => {
                    console.log(context.state.selectedEncounter)
                    context.controller.setProp("openVideoCallModal", true);
                    context.controller.setProp("videoCallRoom", url);
                    context.controller.setProp("selectedEncounter", context.state.selectedEncounter)
                    context.controller.setProp(
                      "currChatChannelId",
                      context.state.chatDetail?.chat_channel
                    );
                    context.controller.setProp(
                      "currPatientId",
                      context.state.selectedPatient?.id
                    );
                  }}
                  onSetChannelName={() => {}}
                  enHasNoDiv={() => {}}
                  enHasDiv={() => {}}
                />
              </div>
            ),
          },
          {
            key: "Package",
            name: "Package",
            hide:
              !["การเงิน", "หน่วยตรวจ", "คลินิกพรีเมี่ยม"].includes(
                context.state.divisionType || ""
              ) || !context.state.selectedPatient,
            render: () => (
              <CardSearchPackage
                setProp={context.controller.setProp}
                onEvent={context.controller.handleEvent}
                // seq
                runSequence={context.runSequence}
                PackagePurchaseSequence={context.state.PackagePurchaseSequence}
                // CommonInterface
                searchedItemListWithKey={context.state.searchedItemListWithKey}
                buttonLoadCheck={context.state.buttonLoadCheck}
                errorMessage={context.state.errorMessage}
                successMessage={context.state.successMessage}
                // options
                masterOptions={context.state.masterOptions}
              />
            ),
          },
          {
            key: "Purchase list",
            name: (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span>Purchase list</span>
                {!!context.state.purchaseOrderList?.length && (
                  <Label
                    data-testid="msg-noti"
                    circular
                    color={"red"}
                    size="mini"
                  >
                    {context.state.purchaseOrderList?.length}
                  </Label>
                )}
              </div>
            ),
            hide:
              !["การเงิน", "หน่วยตรวจ", "คลินิกพรีเมี่ยม"].includes(
                context.state.divisionType || ""
              ) || !context.state.selectedPatient,
            render: () => (
              <CardPurchaseList
                setProp={context.controller.setProp}
                onEvent={context.controller.handleEvent}
                // seq
                runSequence={context.runSequence}
                PackagePurchaseSequence={context.state.PackagePurchaseSequence}
                BillPaymentSequence={context.state.BillPaymentSequence}
                // CommonInterface
                searchedItemListWithKey={context.state.searchedItemListWithKey}
                buttonLoadCheck={context.state.buttonLoadCheck}
                errorMessage={context.state.errorMessage}
                patientInfo={context.state.selectedPatient}
                selectedEncounter={context.state.selectedEncounter}
                selectedDevice={context.state.selectedDevice}
                // options
                masterOptions={context.state.masterOptions}
              />
            ),
          },
          {
            key: "Purchase History",
            name: "Purchase History",
            hide:
              !["การเงิน", "หน่วยตรวจ", "คลินิกพรีเมี่ยม"].includes(
                context.state.divisionType || ""
              ) || !context.state.selectedPatient,
            render: () => (
              <CardPurchaseHistory
                setProp={context.controller.setProp}
                onEvent={context.controller.handleEvent}
                // seq
                runSequence={context.runSequence}
                PackagePurchaseSequence={context.state.PackagePurchaseSequence}
                // data
                masterData={context.controller.data.masterData}
                // Filter
                statusesFilter={["BILLED", "PAID"]}
                // CommonInterface
                searchedItemListWithKey={context.state.searchedItemListWithKey}
                buttonLoadCheck={context.state.buttonLoadCheck}
                errorMessage={context.state.errorMessage}
                // options
                masterOptions={context.state.masterOptions}
              />
            ),
          },
          {
            key: "Apply Policy Package",
            name: "บันทึกใช้แพ็กเกจ",
            hide:
              !["การเงิน", "หน่วยตรวจ", "คลินิกพรีเมี่ยม"].includes(
                context.state.divisionType || ""
              ) || !context.state.selectedPatient,
            render: () => (
              <CardApplyPolicyPackage
                onEvent={context.controller.handleEvent}
                setProp={context.controller.setProp}
                // seq
                runSequence={context.runSequence}
                ApplyPolicyPackageSequence={
                  context.state.ApplyPolicyPackageSequence
                }
                // CommonInterface
                buttonLoadCheck={context.state.buttonLoadCheck}
                errorMessage={context.state.errorMessage}
                // options
              />
            ),
          },
          {
            key: "รับชำระเงินมัดจำ",
            name: "บันทึกรับเงินมัดจำ",
            hide:
              !["การเงิน"].includes(context.state.divisionType || "") ||
              !context.state.selectedPatient,
            render: () => (
              <CardBillingDeposit
                setProp={context.controller.setProp}
                divisionList={context.state.divisionList}
                onEvent={context.controller.handleEvent}
                runSequence={context.runSequence}
                BillingHistorySequence={context.state.BillingHistorySequence}
                errorMessage={context.state.errorMessage}
                successMessage={context.state.successMessage}
                selectedEncounter={context.state.selectedEncounter}
                patientInfo={context.state.selectedPatient}
                BillPaymentSequence={context.state.BillPaymentSequence}
                invoiceItemByItems={context.state.invoiceItemByItems}
                // ReceiptCode
                bilReceiptCodeDetail={context.state.bilReceiptCodeDetail}
                // Deposit
                BillingDepositSequence={context.state.BillingDepositSequence}
                // Bill Patient Language
                billingPatientLanguage={context.state.billingPatientLanguage}
                // options
                masterOptions={context.state.masterOptions}
              />
            ),
          },
          {
            key: "ประวัติการใช้เงินมัดจำ",
            name: "ประวัติการใช้เงินมัดจำ",
            hide:
              !["การเงิน"].includes(context.state.divisionType || "") ||
              !context.state.selectedPatient,
            render: () => (
              <CardBillingDepositHistory
                setProp={context.controller.setProp}
                onEvent={context.controller.handleEvent}
                runSequence={context.runSequence}
                // Deposit
                BillingDepositHistorySequence={
                  context.state.BillingDepositHistorySequence
                }
                billReportPrint={context.state.billReportPrint}
              />
            ),
          },
          {
            key: "แจ้งคืนค่าบริการ",
            name: "แจ้งคืนค่าบริการ",
            hide:
              !["การเงิน"].includes(context.state.divisionType || "") ||
              !context.state.selectedPatient,
            render: () => (
              <CardServiceRefund
                setProp={context.controller.setProp}
                runSequence={context.runSequence}
                selectedPatient={context.state.selectedPatient}
                ORServiceRefundSequence={context.state.ORServiceRefundSequence}
                errorMessage={context.state.errorMessage}
                successMessage={context.state.successMessage}
              />
            ),
          },
          {
            key: "Intra Transfer Form",
            name: "Intra Transfer Form",
            hide: !isClinicWardDivision,
            render: () => (
              <CardIntraHospitalTransfer
                // callback
                runSequence={context.runSequence}
                setProp={context.controller.setProp}
                onEvent={context.controller.handleEvent}
                // data
                intraTransferForm={context.state.intraTransferForm}
                intraHospitalTransferOptions={
                  context.state.intraHospitalTransferOptions
                }
                searchedItemList={context.state.searchedItemList}
                errorMessage={context.state.errorMessage}
                loadingStatus={context.state.loadingStatus}
                masterOptions={context.state.masterOptions}
                selectVitalSignLoading={context.state.selectVitalSignLoading}
                getEncounterVitalSignListErr={
                  context.state.getEncounterVitalSignListErr
                }
                encounterVitalSignList={context.state.encounterVitalSignList}
                hideAdmit={true}
                isRestartClear
              />
            ),
          },
          {
            key: "Print_List_Register",
            name: "Print List",
            render: () => (
              <div>
                {context.state.SetPatientSequence?.patient?.hn !== undefined ? (
                  <ErrorBoundary>
                    <CardPrintList
                      patientInfo={context.state.selectedPatient}
                      controller={context.printListController}
                      patientData={context.state.patientData}
                      patientId={context.state.selectedEncounter?.patient_id}
                      encounterId={context.state.selectedEncounter?.id}
                      encounterNumber={context.state.selectedEncounter?.number}
                      masterOptions={context.state.masterOptions}
                      onClose={context.togglePrintList}
                    />
                  </ErrorBoundary>
                ) : (
                  <div>
                    {context.state.SetPatientSequence?.patient &&
                      alert("กรุณาระบุ HN")}
                  </div>
                )}
              </div>
            ),
          },
          {
            key: "Scanned_Document_Register",
            name: "Scanned Document",
            render: () => (
              <div>
                {context.state.SetPatientSequence?.patient?.hn !== undefined ? (
                  <ErrorBoundary>
                    <CardScannedDocument
                      controller={context.scannedDocumentController}
                      patientData={context.state.patientData}
                      patientInfo={{
                        ...context.state.selectedPatient,
                        encounter_id: null,
                      }}
                      django={context.state.django}
                      isLoading={context.state.eocIsLoading}
                      onClose={context.removeCardScannedDocument}
                    />
                  </ErrorBoundary>
                ) : (
                  <div>
                    {context.state.SetPatientSequence?.patient &&
                      alert("กรุณาระบุ HN")}
                  </div>
                )}
              </div>
            ),
          },
          {
            key: "Scanning Document",
            name: "Scanning Document",
            render: () => (
              <CardScanner
                onClose={() => {
                  context.setState({
                    openModScanner: !context.state.openModScanner,
                  });
                  context.setState({ openMorePopup: false });
                }}
                patientInfo={context.state.selectedPatient}
                controller={context.scannedDocumentController}
                patientId={context.state.selectedEncounter?.patient_id || context.state.selectedPatient?.id}
                encounterId={context.state.selectedEncounter?.id}
                PATIENT_DATA={context.state.patientData}
                onNoPatient={() =>
                  context.setState({ openModScannerError: true })
                }
                django={context.state.django}
                setProp={context.controller.setProp}
              />
            ),
          },

        ]
          // ?.filter((item: any) =>
          //   CONFIG.SCREEN_ITEM?.find((conf: any) => conf.name === item.key)
          // )
          // ?.filter((item: any) => !item.hide)
          ?.filter((item): boolean => {
            const isVisible = !!CONFIG.SCREEN_ITEM?.find(
              (configItem): boolean => configItem.name === item.key && !item.hide
            );

            let tabList: string[] | undefined;

            if (context.state.divisionType === "backoffice") {
              tabList = MainHISI.BACK_OFFICE_REGISTRATION_TAB_LIST;
            } else if (context.state.divisionType === "การเงิน") {
              tabList = MainHISI.BILLING_REGISTRATION_TAB_LIST;
            }

            return isVisible && (!tabList || tabList.includes(item.key));
          })}
      />
    </ErrorBoundary>

}

